import { Howl } from 'howler';

let instance = null;

export default class Audio {
  constructor() {
    if (instance) {
      return instance;
    }

    instance = this;

    //Global access
    window.WVAudio = this;

    this.soundAmbient = null;
    this.soundParty = null;

    this.isAmbientPlaying = false;
    this.isPartyPlaying = false;
    this.isMuted = false;
    Howler.volume(0.2);
  }

  instance = this;

  startAmbient() {
    if (this.isMuted) return;
    if (this.isAmbientPlaying) return;
    if (this.isPartyPlaying) this.soundParty.stop();

    this.isAmbientPlaying = true;
    this.isPartyPlaying = false;

    this.soundAmbient = new Howl({
      src: ['assets/ambient-new.mp3'],
      html5: true,
      loop: true,
      volume: 0.5,
    });

    this.soundAmbient.play();
  }

  startParty() {
    if(this.isMuted) return;
    if(this.isAmbientPlaying) this.soundAmbient.stop();

    this.isAmbientPlaying = false;
    this.isPartyPlaying = true;

    this.soundParty = new Howl({
      src: ['assets/party-new.mp3'],
      html5: true,
      loop: true,
      volume: 0.2,
    });

    this.soundParty.play();
  }

  toggle() {
    if(this.isMuted) {
      if (this.isPartyPlaying) this.soundParty.play();
      if (this.isAmbientPlaying) this.soundAmbient.play();
    } else {
      if (this.isPartyPlaying) this.soundParty.stop();
      if (this.isAmbientPlaying) this.soundAmbient.stop();
    }

    this.isMuted = !this.isMuted;
  }
}


