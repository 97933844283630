import React, { useEffect, useState } from "react";

import * as Styles from './landing.styles';

const getRandomFact = (max) => {
  return Math.floor(Math.random() * (max));
}

let facts = [
  'I LOVE THE OLD STAR WARS TRILOGY',
  'I’M GAMES ENTHUSIAST',
  'I CAN CROCHET',
  'I CRY DURING GOOD LIVE CONCERTS',
  'I’M IN LOVE WITH KINDER CHOCOLATE',
  'I’M REALLY GOOD AT REPRODUCTION PAINTING',
  'I SPEAK 4 LANGUAGES'
];

const initIndex = getRandomFact(facts.length);
let factIndex = initIndex;

const queryString = window.location.search;
const companyParam = new URLSearchParams(queryString).get('company');

const Landing = ({ isLoaded }) => {
  const [changeTrigger, setChangeTrigger] = useState(false);

  useEffect(() => {
    const intervalRef = setInterval(() => {
      if (facts.length === 1) return () => clearInterval(intervalRef);
      let newPosition = 0;
      //Trigger CSS opacity animation
      setChangeTrigger(true);

      setTimeout(() => {
        facts.splice(factIndex, 1);
        newPosition = getRandomFact(facts.length);
      }, 600)

      setTimeout(() => {
        factIndex = newPosition;

        //Trigger CSS opacity animation
        setChangeTrigger(false);
      }, 700)
    }, 4000);

    return () => clearInterval(intervalRef);
  }, []);

  const renderMessage = () => {
    return companyParam ? `Hey ${companyParam}! <br />Loading assets for you!` : 'RANDOM FACTS';
  }

  return (
    <Styles.Wrapper isLoaded={isLoaded}>
      <Styles.Content isLoaded={isLoaded}>
        <Styles.Title dangerouslySetInnerHTML={{ __html: renderMessage() }}></Styles.Title>
        <Styles.Fact shouldChange={changeTrigger}>{facts[factIndex]}</Styles.Fact>
      </Styles.Content>
    </Styles.Wrapper>
  );
};

export default Landing;
