import styled from 'styled-components';
import { media } from '../styles/variables';

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(109.6deg, #E2DFEF 11.42%, #BBB7D0 84.88%);
  pointer-events: none;

  transition: opacity 0.7s;
  opacity: ${props => (props.isLoaded ? 0 : 1)};
  transition-delay: 1.4s;

  ${media.lessThan('sm')`
    display: none;
  `}
`;

export const Content = styled.div`
  padding: 0 64px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;

  transition: opacity 0.7s;
  opacity: ${props => (props.isLoaded ? 0 : 1)};
`;

export const Fact = styled.h2`
  font-weight: 200;
  font-size: 64px;
  line-height: 93%;
  max-width: 825px;
  height: 130px;
  width: 100%;
  text-transform: uppercase;

  transition: opacity 0.7s;
  opacity: ${props => (props.shouldChange ? 0 : 1)};
`;

export const Title = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;
  margin-bottom: 32px;
`;
