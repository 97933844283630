import React, { useState } from 'react';

import * as Styles from './header.styles';

import Switch from './switch';

import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonAlternative,
} from '../styles/buttons.styles';

import infoSrc from '../assets/info-icon.svg';
import downloadSrc from '../assets/download-black-icon.svg';

import closeBlackSrc from '../assets/close-icon-black.svg';

import atWhiteSrc from '../assets/at-white-icon.svg';

import veraSrc from '../assets/vera.png';

const Header = ({ isLoaded, onMenuClickCallback }) => {
  const [isActive, setIsActive] = useState(false);
  const [isSoundOn, setIsSoundOn] = useState(true);

  const handleAudio = () => {
    setIsSoundOn(!isSoundOn);
    window.WVAudio.toggle();
  }

  const activeColor = isActive ? '#23252C' : '#fff';

  return (
    <>
      <Styles.Header>
        <Styles.Menu isLoaded={isLoaded}>
          <Styles.MenuItem>
            <Switch />
          </Styles.MenuItem>
          <Styles.MenuItem >
            <ButtonPrimary
              isActive={isActive}
              href="#"
              title="Sound ON/OFF"
              onClick={() => handleAudio()}
            >
              {
                isSoundOn &&
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.0755 5.32515C10.2026 5.38636 10.3098 5.48216 10.3849 5.60156C10.46 5.72096 10.4999 5.8591 10.5 6.00015V18.0001C10.4998 18.1413 10.4598 18.2795 10.3846 18.3989C10.3093 18.5183 10.2019 18.614 10.0747 18.6751C9.9475 18.7362 9.80562 18.7602 9.6654 18.7443C9.52517 18.7284 9.39228 18.6732 9.282 18.5851L5.7375 15.7501H2.25C2.05109 15.7501 1.86032 15.6711 1.71967 15.5305C1.57902 15.3898 1.5 15.1991 1.5 15.0001V9.00015C1.5 8.80124 1.57902 8.61047 1.71967 8.46982C1.86032 8.32917 2.05109 8.25015 2.25 8.25015H5.7375L9.282 5.41515C9.39236 5.32693 9.52539 5.27168 9.66578 5.25576C9.80616 5.23983 9.94819 5.26389 10.0755 5.32515Z" fill={activeColor} />
                  <path d="M18 7C19.2494 8.35849 20 10.1005 20 12C20 13.8995 19.2494 15.6415 18 17" stroke={activeColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M15 9C15.6247 9.81509 16 10.8603 16 12C16 13.1397 15.6247 14.1849 15 15" stroke={activeColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              }

              {
                !isSoundOn &&
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.0755 5.325C10.2026 5.38621 10.3098 5.48201 10.3849 5.60141C10.46 5.7208 10.4999 5.85895 10.5 6V18C10.4998 18.1411 10.4598 18.2793 10.3846 18.3987C10.3093 18.5181 10.2019 18.6139 10.0747 18.675C9.9475 18.7361 9.80562 18.7601 9.6654 18.7441C9.52517 18.7282 9.39228 18.6731 9.282 18.585L5.7375 15.75H2.25C2.05109 15.75 1.86032 15.671 1.71967 15.5303C1.57902 15.3897 1.5 15.1989 1.5 15V9C1.5 8.80108 1.57902 8.61032 1.71967 8.46967C1.86032 8.32901 2.05109 8.25 2.25 8.25H5.7375L9.282 5.415C9.39236 5.32678 9.52539 5.27153 9.66578 5.2556C9.80616 5.23968 9.94819 5.26373 10.0755 5.325Z" fill={activeColor}/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7133 9.7134C13.3193 9.32638 13.3136 8.69324 13.7007 8.29924C14.0877 7.90524 14.7208 7.89958 15.1148 8.2866L17.4925 10.6221L19.87 8.28668C20.264 7.89966 20.8972 7.90532 21.2842 8.29932C21.6712 8.69332 21.6655 9.32646 21.2715 9.71348L18.9195 12.0239L21.2715 14.3343C21.6655 14.7213 21.6712 15.3544 21.2842 15.7484C20.8972 16.1424 20.264 16.1481 19.87 15.7611L17.4925 13.4256L15.1148 15.7611C14.7208 16.1482 14.0877 16.1425 13.7007 15.7485C13.3136 15.3545 13.3193 14.7214 13.7133 14.3343L16.0654 12.0239L13.7133 9.7134Z" fill={activeColor}/>
                </svg>
              }

              <span className='static-width'>Sound {isSoundOn ? 'On ' : 'Off'}</span>
            </ButtonPrimary>
          </Styles.MenuItem>
          <Styles.MenuItem>
            <ButtonPrimary
              isActive={isActive}
              href="#"
              title="About"
              onClick={() => {
                window.globalState.setIsMenuOpen(!isActive);
                onMenuClickCallback();
                setIsActive(!isActive);
                document.querySelector('.low-fps-prompt').classList.toggle('hide');
              }}
            >
              {isActive ? (
                <Styles.Icon src={closeBlackSrc} alt="About" />
              ) : (
                <Styles.Icon src={infoSrc} alt="About" />
              )}
              <span>{isActive ? 'Close' : 'About'}</span>
            </ButtonPrimary>
          </Styles.MenuItem>
        </Styles.Menu>
      </Styles.Header>

      <Styles.AboutWrapper active={isActive}>
        <Styles.AboutContent>
          <Styles.AboutHeader>WELCOME TO MY</Styles.AboutHeader>
          <Styles.AboutName>WERAVERSE</Styles.AboutName>
          <Styles.AboutIntro>
            While the name may seem a bit outdated, it perfectly captures the essence of what you'll find here.
            Step into my creative space where I immerse myself in a tiny 3D world and have some fun showcasing my passion for design.
          </Styles.AboutIntro>

          <Styles.AboutMe>
            <Styles.VeraImg src={veraSrc} alt='my mugshot' />
            <div>
              <Styles.DescriptionIntro>SHORT ABOUT ME</Styles.DescriptionIntro>
              ✋ I'm Weronika, a <strong>creative UX/UI designer</strong> with a Master's degree in Design and Visual Communication.
              <br /><br />
              I am deeply passionate about <strong>games, 3D and illustration</strong>, and this passion fuels my work. With a fresh, creative, and visionary perspective, I approach each project, consistently pushing the boundaries of what's possible.
            </div>
          </Styles.AboutMe>
        </Styles.AboutContent>

        <Styles.AboutFooter>
          <Styles.Socials>
            <Styles.SocialsTitle>Socials</Styles.SocialsTitle>
            <Styles.SocialLink
              href="https://www.behance.net/weronikaostrowska"
              target="_blank"
              title="Weronika's Behance"
            >
              <span>be</span>
            </Styles.SocialLink>

            <Styles.SocialLink
              href="https://dribbble.com/weronikaostrowska"
              target="_blank"
              title="Weronika's Dribbble"
            >
              <span>db</span>
            </Styles.SocialLink>

            <Styles.SocialLink
              href="https://www.linkedin.com/in/veronicaostrowska"
              target="_blank"
              title="Weronika's Linkedin"
            >
              <span>In</span>
            </Styles.SocialLink>
          </Styles.Socials>

          <Styles.AboutCTAs>
            <Styles.AboutCTAItem>
              <ButtonSecondary href="/Weronika-Ostrowska-resume.pdf" title="CV" download>
                <Styles.Icon src={downloadSrc} alt="CV" />
                <span>Get my resume</span>
              </ButtonSecondary>
            </Styles.AboutCTAItem>
            <Styles.AboutCTAItem>
              <ButtonAlternative
                href="mailto:veronicaostrowska@gmail.com"
                target="_blank"
                title="Contact"
              >
                <Styles.Icon src={atWhiteSrc} alt="Contact" />
                <span>Contact</span>
              </ButtonAlternative>
            </Styles.AboutCTAItem>
          </Styles.AboutCTAs>
        </Styles.AboutFooter>
        <Styles.Credits>
          Developed by <a href="https://www.kryz.me" target="_blank">kryz.me</a>
        </Styles.Credits>
      </Styles.AboutWrapper>
    </>
  );
};

export default Header;
