import * as THREE from 'three';
import Experience from "../Experience";

let instance = null;

export default class IntroAnimPath {
  constructor() {
    if (instance) {
      return instance;
    }
    instance = this;

    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.time = this.experience.time;
    this.debug = this.experience.debug;

    this.animRef = null;

    this.danceTimeoutRef = null;

    this.resource = this.resources.items.introAnimPath;

    this.setModel();
    this.setAnimation();
  }

  setModel() {
    this.model = this.resource.scene;
    this.model.scale.set(100, 100, 100);
    this.model.position.set(0, 0, 0);

    this.animRef = this.model.getObjectByName('Point');

    this.scene.add(this.model);
  }

  setAnimation() {
    this.animation = {};
    this.animation.mixer = new THREE.AnimationMixer(this.model);
    this.animation.anim = this.animation.mixer.clipAction(this.resource.animations[0]);

    this.animation.anim.setLoop(THREE.LoopOnce);
    this.animation.anim.clampWhenFinished = true;
    this.animation.anim.timeScale = 0.4;

    this.animation.mixer.addEventListener('finished', () => {
    this.experience.camera.onCameraAnimationDone();
    this.scene.remove(this.model);
    this.model = null;
  });

    this.animation.anim.play();
  }

  update() {
    this.animation.mixer.update(this.time.delta);
  }
}