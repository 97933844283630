import * as THREE from 'three';
import Experience from "../../Experience";

export default class Linkedin {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.time = this.experience.time;

    this.resource = this.resources.items.linkedinBox;

    this.setModel();
  }

  setModel() {
    this.model = this.resource.scene;
    this.model.position.set(76, 6.5, 6);
    this.model.scale.set(110, 110, 110);

    this.experience.physics.addBox(this.model);
    this.scene.add(this.model);
  }

  click() {
    window.open('https://www.linkedin.com/in/veronicaostrowska', '_blank').focus();
  }
}