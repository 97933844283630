import { generateMedia } from 'styled-media-query';

export const colors = {
  black: '#000',
  white: '#fff'
};

export const media = generateMedia({
  xs: '450px',
  sm: '768px',
  md: '1100px',
  xmd: '1352px',
  lg: '1440px',
  xl: '1920px'
});