import styled from 'styled-components';
import { media } from '../styles/variables';

export const Wrapper = styled.h2`
  .container {
    height: 40px;
    width: 80px;
    background-image: radial-gradient(110.62% 442.5% at 24.38% 50%, #8D7BFF 0%, #5826E4 100%);
    border-radius: 25px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 5px;
    cursor: pointer;
    transition: all .3s;
    position: relative;
    margin-right: 30px;
  }

  .container[data-darkmode="true"] {
    background-image: radial-gradient(124.37% 497.5% at 75.63% 50%, #411187 0%, #5D3DDA 100%);
  }

  .handle {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: grid;
    align-items: center;
    justify-items: center;
    background-color: #fff;
    overflow: hidden;
  }

  .icon {
    color: #f88748;
  }
`;

export const IconOn = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
`;

export const IconOff = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 6px;
`;