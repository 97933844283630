import styled, { css, keyframes } from 'styled-components';
import { motion } from 'framer-motion';

import { media } from '../styles/variables';

const shine = keyframes`
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 100%;
  }
`;

const pulse = keyframes`
  from {
    opacity: 1;
    transform: scale(0.7);
  }

  80% {
    opacity: 1;
  }

  to {
    transform: scale(1.0);
    opacity: 0;
  }
`;

export const Wrapper = styled.div`
  position: fixed;
  text-align: center;
  text-transform: uppercase;
  pointer-events: none;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: ${props => (props.shouldShow ? 1 : 0)};
  transition: opacity 0.2s ease;

  ${media.lessThan('sm')`
    display: none;
  `}
`;

export const BlurBackground = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(20px);
`;

export const Container = styled(motion.div)`
  position: absolute;
  max-width: 431px;
  transform: translateX(-50%);
  bottom: 5%;
  left: 50%;
  font-weight: 400;
  font-size: 40px;
  line-height: 110%;
  width: 100%;
`;

export const AnimContainer = styled.div`
  position: relative;
  z-index: 1;
  width: 70%;
  height: 70%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.5s ease;
  opacity: ${props => (props.shouldShow ? 1 : 0)};
`;

export const Title = styled.div`
  margin-bottom: 48px;
  color: #fff;
`;

export const Highlight = styled.span`
  font-weight: 700;
  font-size: 96px;
  line-height: 93%;
  position: relative;
  top: 20px;
  left: -80px;

  background: linear-gradient(to right, #9B86D6, #4E1AE3);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  background-size: 350% auto;
  animation: ${shine} 5s ease-in-out infinite;
  animation-direction: alternate;
`;

export const StartCTA = styled.div`
  align-items: center;
  position: relative;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  font-weight: 600;
  font-size: 12px;
  line-height: 125%;
  text-align: center;
  margin: 0 auto 48px;
  height: 84px;
  width: 84px;

  &:after {
    content: "";
    position: absolute;
    display: block;
    border-radius: 50%;
    border: 1px solid #fff;
    width: 94px;
    height: 94px;
    left: -6px;
    animation: ${pulse} 3s ease-in-out infinite;
    animation-delay: 0.1s;
  }

  &:before {
    content: "";
    position: absolute;
    display: block;
    border-radius: 50%;
    border: 1px solid #fff;
    width: 120px;
    height: 120px;
    left: -19px;

    animation: ${pulse} 3s ease-out infinite;
  }
`

export const Instruction = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 125%;
  padding: 40px 32px;
  color: #23242C;
  background: #fff;
  border-radius: 30px;
  margin-bottom: 32px;
  text-transform: none;

  ${media.lessThan('md')`
    font-size: 14px;
    padding: 12px 16px;
  `}
`;

export const InstructionImage = styled.img`
  display: block;
  margin: 40px auto 0;
  min-height: 82px;
`;

export const AwardsLink = styled(motion.a)`
  position: fixed;
  left: 0;
  top: 150px;
  z-index: 100;
`;
export const CSSAwards = styled.img``;