export default class LowFps {
  constructor(onLowCallback) {
    this.isReady = false;
    this.isVisible = false;
    this.lowFps = document.querySelector('.low-fps-prompt');
    this.lowFpsClose = document.querySelector('#fps-close');
    this.callback = onLowCallback;

    this.lowFpsClose.onclick = () => {
      this.lowFps.classList.remove('active');
    }
    //Setup
    this.setup();
  }

  setup() {
    setTimeout(() => {
      this.isReady = true;
    }, 5000)
  }

  update() {
    if (this.isReady && window.WFPS <= 25) {
      this.showPrompt();
    }
  }

  showPrompt() {
    if (!this.isVisible) {
      this.lowFps.classList.add('active');
      this.isVisible = true;
    }
  }
}
