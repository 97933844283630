import * as THREE from 'three';
import Experience from "../Experience";

export default class PointLight {
  constructor(settings) {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.settings = settings;

    this.addLight();
  }

  addLight() {
    this.pointLight = new THREE.PointLight(this.settings.color, this.settings.intensity, this.settings.distance);
    this.pointLight.position.set(this.settings.x, this.settings.y, this.settings.z);
    this.scene.add(this.pointLight);
  }
}