let instance = null;

export default class GlobalState {
  constructor() {
    if (instance) {
      return instance;
    }

    instance = this;

    //Global access
    window.globalState = this;

    //Setup
    this.isMenuOpen = false;
    this.isMagicMode = true;
  }

  setIsMenuOpen(isOpen) {
    this.isMenuOpen = isOpen;
  }

  getIsMenuOpen() {
    return this.isMenuOpen;
  }

  setIsMagicMode(isMagicMode = true) {
    this.isMagicMode = isMagicMode;
    var event = new CustomEvent('vero:magic-mode', { 'detail': isMagicMode });

    document.dispatchEvent(event);
  }

  getIsMagicMode() {
    return this.isMagicMode;
  }
}