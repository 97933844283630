import * as THREE from 'three';

import Sizes from './Utils/Sizes';
import Time from './Utils/Time';
import Camera from './Camera';
import Renderer from './Renderer';
import World from './World/World';
import Resources from './Utils/Resources';

import sources from './sources';
import Debug from './Utils/Debug';
import Aim from './World/Aim';
import Physics from './Physics';

import Audio from './Utils/Audio';

let instance = null;
let frames = 0, prevTime = performance.now();

export default class Experience {
  constructor(canvas) {
    if (instance) {
      return instance;
    }

    instance = this;

    //Global access
    window.experience = this;

    //Options
    this.canvas = canvas;

    //Setup
    this.debug = new Debug()
    this.sizes = new Sizes();
    this.time = new Time();
    this.scene = new THREE.Scene();
    this.aim = new Aim();
    this.physics = new Physics();
    this.resources = new Resources(sources);
    this.camera = new Camera();
    this.renderer = new Renderer();
    this.world = new World();

    //Utils
    this.audio = new Audio();

    this.sizes.on('resize', () => {
      this.resize();
    });

    this.time.on('tick', () => {
      this.update();
    });

    const loader = new THREE.TextureLoader();
    loader.load('textures/background-magic2.jpg', (texture) => {
      this.scene.background = texture;
    });
  }

  resize() {
    this.camera.resize();
    this.renderer.resize();
  }

  update() {
    this.debug.start();
    frames ++;
    const time = performance.now();
    if ( time >= prevTime + 1000 ) {
    	frames = Math.round( ( frames * 1000 ) / ( time - prevTime ) );
      window.WFPS = frames;
      frames = 0;
      prevTime = time;
    }
    this.camera.update();
    this.world.update();
    this.renderer.update();
    this.physics.update();

    this.debug.end();
  }

  destroy() {
    this.sizes.off('resize');
    this.time.off('tick');

    this.scene.traverse((child) => {
      if(child instanceof THREE.Mesh) {
        child.geometry.dispose();

        for(const key in child.material) {
          const value = child.material[key];
          if(value && typeof value.dispose === 'function') {
            value.dispose();
          }
        }
      }
    })

    this.camera.controls.dispose();
    this.renderer.instance.dispose();

    if(this.debug.active) {
      this.debug.ui.destroy();
      this.debug.stats.destroy();
    }
  }
}
