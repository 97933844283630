import Experience from '../Experience';

export default class Aim {
  constructor() {
    this.experience = new Experience();
    this.resources = this.experience.resources;

    this.instance = document.querySelector('.aim');
    this.textField = document.querySelector('.aim > span');
    this.isActive = false;
  }

  toggleAim() {
    this.instance.classList.toggle('notVisible');
  }

  setActive() {
    if (!this.isActive) {
      this.instance.classList.add('active');
      this.textField.innerHTML = 'OPEN'
      this.isActive = true;
    }
  }

  setActiveAnimation() {
    if (!this.isActive) {
      this.instance.classList.add('active');
      this.textField.innerHTML = 'DANCE'
      this.isActive = true;
    }
  }

  setInactive() {
    if (this.isActive) {
      this.instance.classList.remove('active');
      this.isActive = false;
    }
  }
}
