import Experience from "../../Experience";

export default class MovingSphereSmall {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;

    this.resource = this.resources.items.sphereSmall;

    this.setModel();
  }

  setModel() {
    this.model = this.resource.scene;
    this.model.position.set(0, 0, 0);
    this.model.scale.set(100, 100, 100);
    this.model.rotation.x += Math.PI * 0.2;

    this.scene.add(this.model);
  }

  update() {
    const delta = this.experience.time.delta;
    this.model.rotation.y -= 0.01 * Math.sin(delta);
    this.model.rotation.x -= 0.03 * Math.sin(delta);
  }

  dispose() {
    this.scene.remove(this.model);

    if (this.model.children[0].geometry) {
      this.model.children[0].geometry.dispose();
    }

    if (this.model.children[0].material) {
        if (this.model.children[0].material.length) {
            for (let i = 0; i < this.model.children[0].material.length; i++) {
              this.model.children[0].material[i].dispose();
            }
        }
        else {
          this.model.children[0].material.dispose();
        }
    }
  }
}