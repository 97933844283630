import styled, { keyframes } from 'styled-components';
import { media } from '../styles/variables';

const shine = keyframes`
	to {
		background-position: 150% center;
	}
`;

export const Header = styled.header`
  position: relative;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 64px 0;
  z-index: 1000;
  justify-content: end;

  ${media.lessThan('sm')`
    display: none;
  `}

  ${media.greaterThan('xl')`
    padding: 90px 64px 0;
  `}

  ${media.lessThan('md')`
    padding: 16px 24px;
    width: 100%;
  `}
`;

export const Menu = styled.menu`
  display: flex;
  gap: 24px;

  transition: opacity 0.2s;
  opacity: ${props => (props.isLoaded ? 1 : 0)};

  ${media.lessThan('md')`
    gap: 44px;
  `}
`;

export const MenuItem = styled.li`
  text-transform: uppercase;
`;

export const Icon = styled.img`
  position: relative;
  z-index: 100;

  ${media.lessThan('md')`
    margin-right: 0;
  `}
`;

export const Link = styled.a``;

export const AboutWrapper = styled.div`
  background: linear-gradient(133.99deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.81) 70.83%, rgba(255, 255, 255, 0.9) 100%);
  backdrop-filter: blur(10px);
  border-radius: 30px 0px 0px 30px;
  color: #23252C;
  height: 100%;
  position: absolute;
  top: 0;
  width: 50%;
  z-index: 10;
  padding: 0 80px;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  right: 0;
  transform: ${props => (props.active ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.5s, opacity 0.5s;
  z-index: 100;

  ${media.lessThan('xmd')`
    width: 65%
  `}

  ${media.lessThan('md')`
    width: 90%
  `}
`;

export const AboutIntro = styled.p`
  color: #23252C;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 48px;

  @media (max-height: 750px) {
    margin-bottom: 32px;
  }
`;

export const AboutContent = styled.div`
  position: relative;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
`;

export const AboutHeader = styled.h3`
  font-weight: 400;
  font-size: 20px;
  line-height: 93%;
  margin-bottom: 16px;
`;

export const AboutName = styled.h2`
  font-weight: 700;
  font-size: 64px;
  line-height: 93%;
  text-transform: uppercase;

  background: linear-gradient(to right, #7985F8 20%, #7556CE 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 32px;

  background-size: 150% auto;
  animation: ${shine} 2s linear infinite;
  animation-direction: alternate;

  @media (max-height: 750px) {
    font-size: 48px;
    margin-bottom: 26px;
  }
`;

export const Instruction = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 125%;
  padding: 24px;
  background: rgba(35, 37, 44, 0.05);
  border-radius: 16px;
  margin-bottom: 32px;
`;

export const InstructionImage = styled.img`
  display: block;
  margin: 16px auto 0;
`;

export const Separator = styled.span`
  background-color: #23252C;
  display: block;
  height: 1px;
  width: 203px;
  margin-bottom: 32px;
`;

export const DescriptionIntro = styled.h4`
  font-weight: 700;
  line-height: 125%;
  font-size: 12px;
  margin-bottom: 16px;
`;

export const AboutMe = styled.div`
  display: flex;
  align-items: center;
  gap: 36px;
  font-weight: 400;
  line-height: 150%;
  border: 1px solid #6E6B83;
  border-radius: 30px;
  padding: 24px;
  font-size: 12px;
`;

export const AboutFooter = styled.footer`
  align-items: end;
  justify-content: space-between;
  position: absolute;
  display: flex;
  bottom: 60px;
  width: 100%;
  left: 0;
  padding: 0 80px;

  @media (max-height: 750px) {
    bottom: 45px;
  }

  @media (min-height: 800px) {
    bottom: 85px;
  }
`;

export const Socials = styled.div`
  display: grid;
  grid-template-areas:
    "title title title"
    "behance dribbble linkedin";
  grid-template-rows: auto;
  grid-template-columns: 50px 50px 50px;

  h5 {
    grid-area: title;
  }

  a {
    &:first-child {
      grid-area: behance;
    }

    &:nth-child(2) {
      grid-area: dribbble;
    }

    &:nth-child(3) {
      grid-area: linkedin;
    }
  }
`;

export const SocialLink = styled.a`
  position: relative;
  border: 1px solid #23252C;
  border-radius: 50%;
  color: #23252C;
  height: 40px;
  width: 40px;
  display: block;
  overflow: hidden;
  font-size: 14px;

  span {
    font-weight: 600;
    display: block;
    text-align: center;
    transform: translate3d(0px, 43%, 0px);
    transition: transform 0.2s;
    transition-timing-function: ease-in-out;
    text-shadow: 0 26px #23252C;
  }

  &:hover {
    span {
      transform: translate3d(0px, -88%, 0px);
      transform-style: preserve-3d;
    }
  }
`;

export const SocialsTitle = styled.h5`
  margin-bottom: 16px;
`;

export const AboutCTAs = styled.menu`
  display: flex;
  gap: 32px;

  ${media.lessThan('md')`
    gap: 0;
  `}
`;

export const AboutCTAItem = styled.li``;

export const VeraImg = styled.img`
  height: 130px;
  width: 130px;
`;

export const Credits = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;

  a {
    color: inherit;
    text-decoration: underline;
  }
`;