import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import Mobile from "./components/mobile";

import Header from './components/header';
import Landing from './components/landing';
import ProgressBar from './components/progressBar';
import Welcome from './components/welcome';

import GlobalStyles from './styles/global.styles';

window.weraDebugMode = window.location.hash === '#debug';

function App() {
  const [isSceneReady, setIsSceneReady] = useState(false);
  const [isMainMenuOpen, setIsMainMenuOpen] = useState(false);
  //TODO: REMOVE when ready
  const [progress, setProgress] = useState(window.weraDebugMode ? 100 : 0);

  useEffect(() => {
    //Handle Loader progress
    const handleEvent = (evt) => {
      if (window.weraDebugMode) return;
      setProgress(Math.round(evt.detail.loaded / evt.detail.total * 100));
    };

    document.addEventListener('vero:loader', (evt) => handleEvent(evt));

    return () => document.removeEventListener('vero:loader', handleEvent);
  }, []);

  useEffect(() => {
    //Handle Scene Ready - initial animation done

    const handleEvent = (evt) => {
      setIsSceneReady(true);
    };

    document.addEventListener('vero:sceneReady', (evt) => handleEvent(evt));

    return () => document.removeEventListener('vero:sceneReady', handleEvent);
  }, [])

  return (
    <>
      <GlobalStyles />
      <Mobile />

      <a href="/" title="logo" className='logo'>
        <svg width="80" height="40" viewBox="0 0 80 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle r="1.5" transform="matrix(1 0 0 -1 74.2285 33.332)" fill={`${progress < 100 ? '#23252C' : '#fff'}`} />
          <circle r="10.224" transform="matrix(1 0 0 -1 57.392 24.608)" fill="#6850FF"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M23.3121 5.58594L32.9681 15.2419L41.917 6.29304L43.3312 7.70726L32.9681 18.0704L23.3121 8.41436L13.6561 18.0704L3.29297 7.70726L4.70718 6.29304L13.6561 15.2419L23.3121 5.58594Z" fill={`${progress < 100 ? '#23252C' : '#fff'}`} />
        </svg>
      </a>
      <Header onMenuClickCallback={() => setIsMainMenuOpen(!isMainMenuOpen)} isLoaded={progress >= 100} />
      <AnimatePresence>
        {
          isSceneReady &&
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {/* <Header onMenuClickCallback={() => setIsMainMenuOpen(!isMainMenuOpen)} isLoaded={progress >= 100} /> */}
            <Welcome isMenuOpen={isMainMenuOpen} isLoaded={progress >= 100} />
          </motion.div>
        }
      </AnimatePresence>

      {!isSceneReady &&
        <>
          <Landing isLoaded={progress >= 100} />
          <ProgressBar progress={progress} />
        </>
      }
    </>
  );
}

export default App;