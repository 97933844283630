import * as THREE from 'three';
import RAPIER from '@dimforge/rapier3d';
import Experience from './Experience';

export default class Physics {
  constructor() {
    this.characterController = null;
    this.characterRigid = null;
    this.characterCollider = null;
    this.movementDirection = { x: 0.0, y: 0.0, z: 0.0 };
    this.playerOrientaion = new Experience();

    this.world = null;

    // collided items
    this.items = [];
    this.bodies = [];

    this.createWorld();
  }

  createWorld() {
    let gravity = { x: 0.0, y: -9.81, z: 0.0 };
    this.world = new RAPIER.World(gravity);

    let groundColliderDesc = RAPIER.ColliderDesc.cuboid(500.0, 0, 500.0);
    this.world.createCollider(groundColliderDesc);

    this.setWorldColiders();
  }

  setWorldColiders() {
    // Center statue
    let colliderDesc1 = RAPIER.ColliderDesc.ball(23).setTranslation(32.0, 10.0, 5.0);
    this.world.createCollider(colliderDesc1);

    // Left three
    let colliderDesc2 = RAPIER.ColliderDesc.ball(12).setTranslation(-90.0, 5.0, -24.0);
    this.world.createCollider(colliderDesc2);

    // Right three
    let colliderDesc3 = RAPIER.ColliderDesc.ball(17).setTranslation(125.0, 8.0, 14.0);
    this.world.createCollider(colliderDesc3);

    // Picture
    let colliderDesc4 = RAPIER.ColliderDesc.ball(8).setTranslation(79.0, 3.0, -27.0);
    this.world.createCollider(colliderDesc4);

    // Wall 1 harmonica
    let colliderDesc5 = RAPIER.ColliderDesc
      .cuboid(3, 10, 20)
      .setTranslation(-2.5, 5, -2.88)
      .setRotation({ w: -0.9274451533346619, x: 0.0, y: 0.373959205737799, z: 0.0 });
    this.world.createCollider(colliderDesc5);

    let colliderDesc6 = RAPIER.ColliderDesc
      .cuboid(5, 10, 20)
      .setTranslation(-19.12, 5, -4)
      .setRotation({ w: -0.36520176189158593, x: 0.0, y: 0.9309283931169364, z: 0.0 });

      this.world.createCollider(colliderDesc6);

    let colliderDesc7 = RAPIER.ColliderDesc
      .cuboid(4, 10, 21)
      .setTranslation(-34, 5, -2.88)
      .setRotation({ w: -0.9274451533346619, x: 0.0, y: 0.373959205737799, z: 0.0 });
    this.world.createCollider(colliderDesc7);

    let colliderDesc8 = RAPIER.ColliderDesc
      .cuboid(5, 10, 20)
      .setTranslation(-52.82, 5, -3)
      .setRotation({ w: -0.36520176189158593, x: 0.0, y: 0.9309283931169364, z: 0.0 });

    this.world.createCollider(colliderDesc8);

    // Wall 2 e-school
    let colliderDesc9 = RAPIER.ColliderDesc
      .cuboid(5, 10, 30)
      .setTranslation(-36, 5, 64)
      .setRotation({ w: -0.49545866843240954, x: 0.0, y: 0.8686315144381901, z: 0.0 });

    this.world.createCollider(colliderDesc9);

    let colliderDesc10 = RAPIER.ColliderDesc
      .cuboid(5, 10, 44)
      .setTranslation(29.5, 5, 66)
      .setRotation({ w: -0.839240452652381, x: 0.0, y: 0.5437604827787935, z: 0.0 });

    this.world.createCollider(colliderDesc10);

    // Wall 3 coaching
    let colliderDesc11 = RAPIER.ColliderDesc
      .cuboid(7, 10, 36)
      .setTranslation(34, 5, -64)
      .setRotation({ w: -0.5750052520432775, x: 0.0, y: 0.8181497174250242, z: 0.0 });

    this.world.createCollider(colliderDesc11);

    let colliderDesc12 = RAPIER.ColliderDesc
      .cuboid(7, 10, 37)
      .setTranslation(-30, 5, -71)
      .setRotation({ w: 0.5490228179981316, x: 0.0, y: 0.8358073613682704, z: 0.0 });

    this.world.createCollider(colliderDesc12);

    // Surrounding Walls
    let colliderDesc13 = RAPIER.ColliderDesc
      .cuboid(6, 10, 50)
      .setTranslation(-79, 5, -86)
      .setRotation({ w: 0.42577929156507327, x: 0.0, y: 0.9048270524660192, z: 0.0 });

    this.world.createCollider(colliderDesc13);

    let colliderDesc14 = RAPIER.ColliderDesc
      .cuboid(5, 10, 28)
      .setTranslation(-120, 5, 28)
      .setRotation({ w: -0.0878511965507423, x: 0.0, y: 0.9961336091431725, z: 0.0 });

    this.world.createCollider(colliderDesc14);

    let colliderDesc15 = RAPIER.ColliderDesc
      .cuboid(6, 10, 20)
      .setTranslation(-19, 5, 123)
      .setRotation({ w: 0.6981654189934745, x: 0.0, y: 0.7159364830218294, z: 0.0 });

    this.world.createCollider(colliderDesc15);

    let colliderDesc16 = RAPIER.ColliderDesc
      .cuboid(6, 10, 20)
      .setTranslation(100, 5, 69)
      .setRotation({ w: 0.2910361668282728, x: 0.0, y: 0.9567120515588302, z: 0.0 });

    this.world.createCollider(colliderDesc16);

    let colliderDesc17 = RAPIER.ColliderDesc
      .cuboid(6, 10, 20)
      .setTranslation(84, 5, -78)
      .setRotation({ w: -0.36520176189158593, x: 0.0, y: 0.9309283931169364, z: 0.0 });

    this.world.createCollider(colliderDesc17);
  }

  addBox(animatedItem) {
    const { x, y, z } = animatedItem.position;

    const rigidBody = this.world.createRigidBody(
      RAPIER.RigidBodyDesc.dynamic()
        .setTranslation(x, y, z)
        .setRotation({ w: 1.0, x: 0.0, y: 0.0, z: 0.0 })
    );

    var box = new THREE.Box3().setFromObject(animatedItem);

    var size = box.getSize(new THREE.Vector3());
    var width = size.x;
    var height = size.y;
    var depth = size.z;

    const colliderDesc = RAPIER.ColliderDesc.cuboid(
      width / 2,
      height / 2,
      depth / 2,
    );

    this.world.createCollider(colliderDesc, rigidBody);
    this.registerItem(rigidBody, animatedItem);
  }

  addCharacterController() {
    const speed = 0.1;
    this.movementDirection = { x: 0.0, y: -speed, z: 0.0 };
    this.characterController = this.world.createCharacterController(0.1);
    this.characterController.enableAutostep(0.7, 0.3, true);
    this.characterController.enableSnapToGround(0.7);
    this.characterController.setMaxSlopeClimbAngle(Math.PI * 0.5);

    this.characterRigid = this.world.createRigidBody(
      RAPIER.RigidBodyDesc.kinematicPositionBased().setTranslation(0, 1, 0),
    );

    let characterColliderDesc = RAPIER.ColliderDesc.cylinder(1.2, 0.6);
    this.characterCollider = this.world.createCollider(
      characterColliderDesc,
      this.characterRigid,
    );
  }

  registerItem(item, body) {
    if (item) this.items.push(item);
    if (body) this.bodies.push(body);
  }

  update() {
    const countItems = this.items.length;

    if (this.world && countItems > 0) {
      this.world.step();

      // handle body movement
      if (this.characterController) {
        this.characterController.computeColliderMovement(
          this.characterCollider,
          this.playerOrientaion.camera.translation
        );

        const movement = this.characterController.computedMovement();
        const newPos = this.characterRigid.translation();
        newPos.x += movement.x;
        newPos.y += movement.y;
        newPos.z += movement.z;

        this.characterRigid.setNextKinematicTranslation(newPos);
        this.playerOrientaion.camera.updateCameraPosition(newPos);
      }
    }
  }
}