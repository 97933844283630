import * as THREE from 'three';
import { clamp } from 'three/src/math/MathUtils';

import Experience from './Experience';
import IntroAnimPath from './Utils/IntroAnimPath';

export default class Camera {
  constructor() {
    this.experience = new Experience();
    this.initAnimPath = null;

    this.sizes = this.experience.sizes;
    this.scene = this.experience.scene;
    this.canvas = this.experience.canvas;
    this.debug = this.experience.debug;
    this.resources = this.experience.resources;
    this.aim = this.experience.aim;

    //Flag when animation finished
    this.sceneDone = false;

    this.moveForward = false;
    this.moveBackward = false;
    this.moveLeft = false;
    this.moveRight = false;
    this.canJump = false;
    this.isLocked = false;

    this.velocity = new THREE.Vector3();

    this.raycaster = null;
    this.collisionObjects = [];

    this.rotation = new THREE.Quaternion();
    this.translation = new THREE.Vector3(0, 0, -25);
    //Initial PHI & THETA VALUES to calculate initial camera rotation
    //No calc just console log when moving mouse
    this.phi = -2.7587999999999915;
    this.theta = 0.08360000000000056;
    this.phiSpeed = 5 * 0.5;
    this.thetaSpeed = 5 * 0.5;
    this.moveSpeed = 25;
    this.forwardVelocity = 0;
    this.strafeVelocity = 0;
    this.mouseSpeed = 0.76;
    this.mouse = {
      x: 0,
      y: 0,
    };

    //flashlight
    this.spotlight = null;

    this.setInstance();
    this.setPointerControls();
    this.setEvents();

    this.runCameraAnimation();

    this.resources.on('loaded', () => {
      this.initAnimPath = new IntroAnimPath();
    });
  }

  // Spawn camera and its intial position
  setInstance() {
    this.instance = new THREE.PerspectiveCamera(
      45,
      this.sizes.width / this.sizes.height,
      0.5,
      1300,
    );

    if (this.debug.active) {
    this.debugFolder = this.debug.ui.addFolder('Camera');
      this.debugFolder
        .add(this.instance.rotation, 'x')
        .name('PL1 - X')
        .min(-10)
        .max(10)
        .step(0.001);

      this.debugFolder
        .add(this.instance.rotation, 'y')
        .name('PL1 - Y')
        .min(-10)
        .max(10)
        .step(0.001);

      this.debugFolder
        .add(this.instance.rotation, 'z')
        .name('PL1 - Z')
        .min(-10)
        .max(10)
        .step(0.001);
    }
    this.scene.add(this.instance);
  }

  // Set mouse events corresponding to camera movement
  setEvents() {
    // Custom Event
    const event = new Event('EventIsLocked', { bubbles: true });
    // Handle Mouse Lock Event
    this.canvas.requestPointerLock = this.canvas.requestPointerLock || this.canvas.mozRequestPointerLock;
    document.exitPointerLock = document.exitPointerLock || document.mozExitPointerLock;

    document.addEventListener('mousemove', e => this.handleMouseMove(e));
    document.addEventListener('pointerlockchange', () => this.handleUnlock(), false);
    document.addEventListener('mozpointerlockchange', () => this.handleUnlock(), false);

    this.canvas.onclick = () => {
      if (!this.sceneDone) return;
      if (!this.isLocked && !window.globalState.isMenuOpen) {
        this.canvas.requestPointerLock();
        // Dispatch custom event for React hide welcome effect
        this.canvas.dispatchEvent(event);
      }
    };
  }

  //handle mouse unlock
  handleUnlock() {
    this.isLocked = !this.isLocked;
    const prompt = document.querySelector('.focus-prompt');

    //Toggle ESC prompt
    prompt.classList.toggle('active');
    //Toggle Aim
    this.aim.toggleAim();
    //Start audio
    this.experience.audio.startAmbient();

    if (!this.isLocked) {
      const event = new Event('EventIsUnlocked', { bubbles: true });
      this.canvas.dispatchEvent(event);
    }
  }

  // Handle body rotation
  handleMouseMove = (e) => {
    if (!this.isLocked && this.sceneDone) return;

    const xh = e.movementX * 0.002 * this.mouseSpeed;
    const yh = e.movementY * 0.002 * this.mouseSpeed;

    //Used for sway
    this.mouse.x = e.movementX * 1;
    this.mouse.y = e.movementY * 1;

    this.phi = this.phi + -xh * this.phiSpeed;
    this.theta = clamp(
      this.theta + -yh * this.thetaSpeed,
      -Math.PI * 0.51, //0.51 to fix approximation issue
      Math.PI * 0.51
    );
  };

  // Frames: Update rotation
  updateRotation() {
    const qx = new THREE.Quaternion();
    qx.setFromAxisAngle(new THREE.Vector3(0, 1, 0), this.phi);
    const qz = new THREE.Quaternion();
    qz.setFromAxisAngle(new THREE.Vector3(1, 0, 0), this.theta);

    const q = new THREE.Quaternion();
    q.multiply(qx);
    q.multiply(qz);

    this.rotation.copy(q);
    this.instance.quaternion.copy(this.rotation);
  }

  // Set keyboard events corresponding to player movement
  setPointerControls() {
    const onKeyDown = (event) => {
      switch (event.code) {
        case 'ArrowUp':
        case 'KeyW':
          this.moveForward = true;
          break;

        case 'ArrowLeft':
        case 'KeyA':
          this.moveLeft = true;
          break;

        case 'ArrowDown':
        case 'KeyS':
          this.moveBackward = true;
          break;

        case 'ArrowRight':
        case 'KeyD':
          this.moveRight = true;
          break;

        case 'Space':
          if (this.canJump === true) this.velocity.y += 350;
          this.canJump = false;
          break;
      }
      this.storeVelocity();
    };

    const onKeyUp = (event) => {
      switch (event.code) {
        case 'ArrowUp':
        case 'KeyW':
          this.moveForward = false;
          break;

        case 'ArrowLeft':
        case 'KeyA':
          this.moveLeft = false;
          break;

        case 'ArrowDown':
        case 'KeyS':
          this.moveBackward = false;
          break;

        case 'ArrowRight':
        case 'KeyD':
          this.moveRight = false;
          break;
      }
      this.storeVelocity();
    };

    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keyup', onKeyUp);
  }

  // Frames: update body position
  updateTranslation() {
    const delta = this.experience.time.delta;
    const qx = new THREE.Quaternion();
    qx.setFromAxisAngle(new THREE.Vector3(0, 1, 0), this.phi);

    const forward = new THREE.Vector3(0, 0, -1);
    forward.applyQuaternion(qx);

    const left = new THREE.Vector3(-1, 0, 0);
    left.applyQuaternion(qx);

    forward.multiplyScalar(this.forwardVelocity * delta);
    left.multiplyScalar(this.strafeVelocity * delta);

    this.translation = forward.add(left);
  }

  // Store body velocity
  storeVelocity() {
    this.forwardVelocity =
      (this.moveForward ? 1 * this.moveSpeed : 0) +
      (this.moveBackward ? -1 * this.moveSpeed : 0);

    this.strafeVelocity =
      (this.moveLeft ? 1 * this.moveSpeed : 0) +
      (this.moveRight ? -1 * this.moveSpeed : 0);
  }

  // Physics Frames: Update body position from character controller
  updateCameraPosition(position) {
    if (!this.isLocked) return;

    this.instance.position.set(position.x, position.y + 10, position.z);
  }

  runCameraAnimation() {
    if(this.initAnimPath) {
      this.instance.position.x = this.initAnimPath.animRef.position.x * 100;
      this.instance.position.y = this.initAnimPath.animRef.position.y * 100 + 10;
      this.instance.position.z = this.initAnimPath.animRef.position.z * 100;

      this.instance.rotation.copy(this.initAnimPath.animRef.rotation);
    }
  }

  onCameraAnimationDone() {
    this.sceneDone = true;

    //Trigger scene ready
    //Show UI components etc
    const event = new CustomEvent('vero:sceneReady');
    document.dispatchEvent(event);
  }

  resize() {
    this.instance.aspect = this.sizes.width / this.sizes.height;
    this.instance.updateProjectionMatrix();
  }

  update() {
    if(!this.sceneDone) this.runCameraAnimation();
    if (!this.isLocked) return;

    this.updateRotation();
    this.updateTranslation();
  }
}
