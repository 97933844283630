import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import * as Styles from './switch.styles';

import magicOnSrc from '../assets/magic-off-icon.svg';
import magicOffSrc from '../assets/magic-on-icon.svg';

const Switch = () => {
  const [isOn, setIsOn] = useState(true);

  return (
    <Styles.Wrapper>
      <div
        className="container"
        data-darkmode={isOn}
        onClick={() => {
          window.globalState.setIsMagicMode(!isOn);
          setIsOn(!isOn)
        }}
        style={{ justifyContent: isOn ? 'flex-end' : 'flex-start' }}
      >
        <motion.div layout className="handle">
          <AnimatePresence initial={false}>
            <Styles.IconOff key="isOn" src={magicOffSrc} alt="OFF" />
            <Styles.IconOn key="isOff" src={magicOnSrc} alt="ON" />
            <motion.i
              className={`icon far fa-${isOn ? 'moon' : 'sun'}`}
              key={isOn ? 'moon' : 'sun'}
              initial={{ y: -30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 30, opacity: 0 }}
              transition={{ duration: .2 }}
            />
          </AnimatePresence>
        </motion.div>
      </div>
    </Styles.Wrapper>
  )
}

export default Switch;