import styled from 'styled-components';
import { media } from '../styles/variables';

import backgrounSrc from '../assets/mobile-background.jpg';

export const Wrapper = styled.div`
  position: fixed;
  display: none;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #E2DFEF;
  z-index: 1000;
  overflow: hidden;

  ${media.lessThan('sm')`
    display: block;
  `}
`;

export const Header = styled.header`
  background-image: url(${backgrounSrc});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 100%;
  padding: 24px 40px;
  pointer-events: none;
`;

export const InfoWrapper = styled.div`
  position: absolute;
  bottom: 400px;
  left: 0;
  padding: 0 50px;
`;

export const HelloText = styled.h2`
  font-weight: 400;
  font-size: 18px;
  line-height: 93%;
  margin-bottom: 16px;
  color: #fff;
  text-align: center;

  @media (max-height: 670px) {
    font-size: 16px;
  }
`;

export const InfoText = styled.p`
  font-weight: 700;
  font-size: 22px;
  line-height: 125%;
  color: #FFFF;
  text-align: center;

  span {
    background: linear-gradient(89.61deg, #6951FF 23.75%, #894EE3 75.49%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  @media (max-height: 670px) {
    font-size: 18px;
  }
`;

export const Footer = styled.footer`
  background: linear-gradient(90.01deg, #6850FF 2.7%, #36016A 125.09%);
  position: relative;
  color: #FFFFFF;
  padding: 32px 24px;
  height: 340px;
  position: absolute;
  bottom: 0;
  width: 100%;
`;

export const FooterText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  margin: 0 auto 16px auto;
  width: 224px;
`;

export const Socials = styled.div`
  display: flex;
  justify-content: space-around;
  width: 210px;
  margin: 0 auto 16px auto;
`;

export const Social = styled.a``;

export const Icon = styled.img`
  margin-right: 8px;
  position: relative;
  z-index: 100;

  ${media.lessThan('md')`
    margin-right: 0;
  `}
`;

export const AnimContainer = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 130%;
  left: 50%;
  transform: translate(-50%, -50%) scale(2.5);
`;

export const Logo = styled.h1`
  position: absolute;
  top: -120px;
  left: 50%;
  transform: translateX(-50%);

  svg {
    transform: scale(2);
  }

  @media (max-height: 670px) {
      top: -90px;

      svg {
        transform: scale(1.5);
      }
    }
`;