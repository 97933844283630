import * as THREE from 'three';
import Experience from '../../Experience';

export default class ThreeParticles {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.material;
    this.particles;

    this.setup();
  }

  setup() {
    const vertices = [];

    for (let i = 0; i < 500; i++) {
      const x = THREE.MathUtils.randFloatSpread(100);
      const y = THREE.MathUtils.randFloatSpread(100);
      const z = THREE.MathUtils.randFloatSpread(100);

      vertices.push(x, y, z);
    }

    const sprite = new THREE.TextureLoader().load('textures/disc.png');
    sprite.colorSpace = THREE.SRGBColorSpace;

    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute(
      'position',
      new THREE.Float32BufferAttribute(vertices, 3),
    );

    this.material = new THREE.PointsMaterial({
      size: 0.5,
      sizeAttenuation: true,
      map: sprite,
      alphaTest: 0.5,
      transparent: true,
      color: new THREE.Color(0x8800d6)
    });

    this.particles = new THREE.Points(geometry, this.material);

    this.particles.position.x = 120;
    this.particles.position.y = 34;
    this.particles.position.z = 10;

    this.scene.add(this.particles);
  }

  update() {
    const delta = this.experience.time.delta;

    if (this.particles) {
      this.particles.rotation.y += (0.05 * delta);
      this.particles.rotation.z += (0.02 * delta)
    }
  }

  dispose() {

  }
}
