import React, { useEffect, useState, useRef } from 'react';
import { AnimatePresence } from 'framer-motion';
import bodymovin from 'bodymovin';

import * as Styles from './welcome.styles';

import instructionSrc from '../assets/instruction-dark.svg';
import awardsIconSrc from '../assets/CSSAwards.png';

let lottie = null;

const Welcome = ({ isLoaded, isMenuOpen }) => {
  const [isLocked, setIsLocked] = useState(false);
  const animContainerRef = useRef(null);

  useEffect(() => {
    document.addEventListener('EventIsLocked', () => handleLock(), false);
    document.addEventListener('EventIsUnlocked', () => handleLock(), false);

    const handleLock = () => {
      setIsLocked(!isLocked);
    };

    return () => {
      document.removeEventListener('EventIsLocked', handleLock, false);
      document.removeEventListener('EventIsUnlocked', handleLock, false);
    };
  }, [isLocked]);

  useEffect(() => {
    if (animContainerRef.current && !lottie) {
      lottie = bodymovin.loadAnimation({
        container: animContainerRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        name: 'star-background',
        path: '/assets/particles.json'
      }).setSpeed(1);
    }
  }, []);

  const shouldShow = isLoaded && !isLocked;

  return (
    <>
      <Styles.Wrapper shouldShow={shouldShow}>
        <Styles.AnimContainer shouldShow={shouldShow}>
          <div ref={animContainerRef} />
        </Styles.AnimContainer>

        <AnimatePresence>
          {shouldShow && (
            <Styles.BlurBackground
              key="blur-back"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1 }}
            />
          )}
        </AnimatePresence>

        <AnimatePresence>
          {!isMenuOpen &&
            <Styles.Container
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Styles.Title>
                Welcome to my
                <br />
                <Styles.Highlight>Weraverse</Styles.Highlight>
              </Styles.Title>

              <Styles.StartCTA>CLICK TO START</Styles.StartCTA>

              <Styles.Instruction>
                Use your cursor to find direction, use arrows or WSAD to move through
                this space. Follow hints and instructions to take an action.
                <Styles.InstructionImage src={instructionSrc} alt="Instruction" />
              </Styles.Instruction>
            </Styles.Container>
          }
        </AnimatePresence>
      </Styles.Wrapper>

      <AnimatePresence>
        {shouldShow &&
          <Styles.AwardsLink
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            href="https://www.cssdesignawards.com/sites/weraverse/43703/" target="_blank">
            <Styles.CSSAwards src={awardsIconSrc} />
          </Styles.AwardsLink>
        }
      </AnimatePresence>
    </>
  );
};

export default Welcome;
