import * as THREE from 'three';
import Experience from "../../Experience";

export default class Dribbble {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.time = this.experience.time;

    this.resource = this.resources.items.dribbbleBox;

    this.setModel();
  }

  setModel() {
    this.model = this.resource.scene;
    this.model.position.set(2, 4.5, 50);
    this.model.scale.set(95, 95, 95);
    this.scene.add(this.model);

    this.experience.physics.addBox(this.model);
  }

  click() {
    window.open('https://dribbble.com/weronikaostrowska', '_blank').focus();
  }
}