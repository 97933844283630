import React, { useEffect, useState } from "react";

import * as Styles from './progressBar.styles';

const ProgressBar = ({ progress }) => {
  const [phase, setPhase] = useState('');
  const shouldAnimate = progress >= 100;
  const pxWidth = window.innerWidth / (100 / progress);

  const variants = {
    phase1: {
      height: window.innerHeight,
      transition: {
        ease: 'easeIn',
        duration: 0.5
      }
    },
    phase2: {
      height: window.innerHeight,
      y: -window.innerHeight,
      transition: { duration: 0.5 }
    }
  }

  useEffect(() => {
    if (shouldAnimate) {
      setTimeout(() => {
        setPhase('phase1');
      }, 800)

      setTimeout(() => {
        setPhase('phase2');
      }, 1500)
    }
  }, [shouldAnimate])

  return (
    <Styles.Wrapper isLoaded={progress >= 100}>
      <Styles.Number isLoaded={shouldAnimate}>{progress}%</Styles.Number>
      <Styles.Bar variants={variants} animate={phase}>
        <Styles.BarBackground progress={pxWidth} />
      </Styles.Bar>
    </Styles.Wrapper>
  );
};

export default ProgressBar;


//1000
//100
//500