import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

export default createGlobalStyle`
  ${reset}

  * {
    box-sizing: border-box;
  }

  strong,
  b {
    font-weight: 700;
  }

  body {
    font-family: 'Kumbh Sans', sans-serif;
    background: linear-gradient(109.6deg, #E2DFEF 11.42%, #BBB7D0 84.88%);
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  a {
    text-decoration: none;
    color: white;
  }
`;