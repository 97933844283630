import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  pointer-events: none;
`;

export const Number = styled.div`
  text-transform: uppercase;
  background: linear-gradient(91.17deg, #320395 80.49%, #450083 104.5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 600;
  font-size: 32px;
  line-height: 30px;
  position: absolute;
  bottom: 57px;
  right: 64px;

  transition: opacity 0.5s;
  opacity: ${props => (props.isLoaded ? 0 : 1)};
`;

export const Bar = styled(motion.div)`
  height: 16px;
  width: 100%;
`;

export const BarBackground = styled.div`
  height: 100%;
  width: ${props => props.progress}px;
  background: linear-gradient(90.01deg, #6850FF 2.7%, #36016A 120.91%);
  transition: width 0.2s;
`;