import * as THREE from 'three';
import Experience from "../Experience";

export default class Wera {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.time = this.experience.time;
    this.debug = this.experience.debug;

    this.danceTimeoutRef = null;

    if(this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder('Wera');
      this.debugFolder.close();
    }

    this.resource = this.resources.items.weraModel;

    this.setModel();
    this.setAnimation();
  }

  setModel() {
    this.model = this.resource.scene;
    this.model.scale.set(15, 15, 15);
    this.model.position.set(30, 4, 5);
    this.model.rotation.y += Math.PI;

    this.scene.add(this.model);

    // this.model.traverse((child) => {
    //   if(child instanceof THREE.Mesh) {
    //     child.castShadow = true;
    //   }
    // })
  }

  setAnimation() {
    this.animation = {};
    this.animation.mixer = new THREE.AnimationMixer(this.model);

    this.animation.actions = {};
    this.animation.actions.dance = this.animation.mixer.clipAction(this.resource.animations[1]);
    this.animation.actions.idle = this.animation.mixer.clipAction(this.resource.animations[0]);

    this.animation.actions.current = this.animation.actions.idle;
    this.animation.actions.current.play();

    // Debug
    if(this.debug.active) {
      const debugObject = {
        playIdle: () => { this.play('idle') },
        playDancing: () => { this.play('dance') },
      }
      this.debugFolder.add(debugObject, 'playIdle');
      this.debugFolder.add(debugObject, 'playDancing');
    }
  }

  play = (name) => {
    const newAction = this.animation.actions[name];
    const oldAction = this.animation.actions.current;

    newAction.reset();
    newAction.play();
    newAction.crossFadeFrom(oldAction, 1);

    this.animation.actions.current = newAction;
  }

  playDance = () => {
    this.play('dance');
    this.experience.audio.startParty();

    clearTimeout(this.danceTimeoutRef);

    this.danceTimeoutRef = setTimeout(() => {
      this.play('idle');
      this.experience.audio.startAmbient();
    }, 9500)
  }

  update() {
    this.animation.mixer.update(this.time.delta);
  }
}