export default [
  {
    name: 'environmentMapTexture',
    type: 'cubeTexture',
    path:
    [
        'textures/environmentMap/px.jpg',
        'textures/environmentMap/nx.jpg',
        'textures/environmentMap/py.jpg',
        'textures/environmentMap/ny.jpg',
        'textures/environmentMap/pz.jpg',
        'textures/environmentMap/nz.jpg'
    ]
  },
  {
    name: 'sceneTexture',
    type: 'texture',
    path: 'textures/background.jpg'
  },
  {
    name: 'weraModel',
    type: 'gltfModel',
    path: 'models/wera.gltf'
  },
  {
    name: 'world',
    type: 'gltfModel',
    path: 'models/world.gltf'
  },
  {
    name: 'dribbbleBox',
    type: 'gltfModel',
    path: 'models/dribble-box.gltf'
  },
  {
    name: 'linkedinBox',
    type: 'gltfModel',
    path: 'models/linkedin-box.gltf'
  },
  {
    name: 'behanceBox',
    type: 'gltfModel',
    path: 'models/behance-box.gltf'
  },
  {
    name: 'sphereBig',
    type: 'gltfModel',
    path: 'models/sphere-bigger-old.gltf'
  },
  {
    name: 'sphereSmall',
    type: 'gltfModel',
    path: 'models/sphere-smaller-old.gltf'
  },
  {
    name: 'introAnimPath',
    type: 'gltfModel',
    path: 'models/camera-path.gltf'
  }
];