import styled from 'styled-components';
import { media } from './variables';

export const ButtonPrimary = styled.a`
  align-items: center;
  background: none;
  display: flex;
  flex-direction: row;
  font-weight: 600;
  font-size: 14px;
  padding: 7px 20px;
  gap: 8px;
  transition: background 0.2s, color 0.1s;
  border-radius: 50px;
  min-width: 131px;
  color: ${props => (props.isActive ? '#23252C' : '#fff')};
  overflow: hidden;

  span {
    transform: translate3d(0px, 0%, 0px);
    transition: transform 0.2s;
    transition-timing-function: ease-in-out;
    text-shadow:  ${props => (props.isActive ? '0 25px #23252C' : '0 25px #fff')};

    &.static-width {
      width: 95px;
    }
  }

  &:hover {
    span {
      transform: translate3d(0px, -180%, 0px);
      transform-style: preserve-3d;
    }
  }

  ${media.lessThan('sm')`
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    padding: 14px 20px;

    span {
      color: #23242C;
      text-transform: uppercase;
      text-shadow: none;
    }

    &:hover {
      span {
        transform: translate3d(0px, 0%, 0px);
      }
    }
  `}
`;

export const ButtonSecondary = styled.a`
  border: 1px solid #23252C;
  border-radius: 50px;
  color: #23252C;
  font-weight: 600;
  font-size: 14px;
  line-height: 93%;
  text-align: center;
  text-transform: uppercase;
  padding: 8px 20px;
  align-items: center;
  background: none;
  display: flex;
  flex-direction: row;
  gap: 8px;
  overflow: hidden;

  span {
    transform: translate3d(0px, 0%, 0px);
    transition: transform 0.2s;
    transition-timing-function: ease-in-out;
    text-shadow: 0 26px #23252C;
  }

  &:hover {
    span {
      transform: translate3d(0px, -200%, 0px);
      transform-style: preserve-3d;
    }
  }

  ${media.lessThan('sm')`
    border-color: #fff;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    padding: 13px 20px;

    span {
      color: #fff;
      text-shadow: none;
    }

    &:hover {
      span {
        transform: translate3d(0px, 0%, 0px);
      }
    }
  `}
`;

export const ButtonAlternative = styled.a`
  align-items: center;
  background: none;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 8px;
  color: #fff;
  padding: 13.5px 20px 13.5px 55px;
  background: #23252C;
  border-radius: 50px;
  font-weight: 600;
  font-size: 14px;
  line-height: 93%;
  text-transform: uppercase;
  transition: backround 0.2s, color 0.2s;
  overflow: hidden;

  span {
    transform: translate3d(0px, 0%, 0px);
    transition: transform 0.2s;
    transition-timing-function: ease-in-out;
    text-shadow: 0 26px #fff;
  }

  &:hover {
    span {
      transform: translate3d(0px, -200%, 0px);
      transform-style: preserve-3d;
    }
  }

  img {
    position: absolute;
    left: 18px;
    transition: opacity 0.2s;
  }

  ${media.lessThan('sm')`
    &:hover {
      span {
        transform: translate3d(0px, 0%, 0px);
      }
    }
  `}
`;