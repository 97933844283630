import * as THREE from 'three';
import Experience from '../../Experience';

export default class ThreeParticles {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.camera = this.experience.camera;
    this.spotLight = null;

    this.setup();
  }

  setup() {
    this.spotLight = new THREE.SpotLight( 0xffffff, 30.0, 80, Math.PI * 0.15, 0.8, 1 );
    this.camera.instance.add(this.spotLight);
    this.camera.instance.add(this.spotLight.target);
    this.spotLight.target.position.z = -6;
    this.spotLight.target.position.y = 0;
  }
}
