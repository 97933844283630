import * as THREE from 'three';
import Experience from '../Experience';

export default class Floor {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;

    // this.setGeometry();
    // this.setTextures();
    // this.setMaterial();
    // this.setMesh();s

    //Set Floor Model
    this.setFloorModel();
  }

  setGeometry() {
    this.geometry = new THREE.PlaneGeometry(200, 200, 50, 100 );
    this.geometry.toNonIndexed();
  }

  setTextures() {
    this.textures = {};
    this.textures.color = this.resources.items.grassColorTexture;
    this.textures.color.encoding = THREE.sRGBEncoding;
    this.textures.color.repeat.set(1.5, 1.5);
    this.textures.color.wrapS = THREE.RepeatWrapping;
    this.textures.color.wrapT = THREE.RepeatWrapping;

    this.textures.normal = this.resources.items.grassNormalTexture;
    this.textures.normal.repeat.set(1.5, 1.5);
    this.textures.normal.wrapS = THREE.RepeatWrapping;
    this.textures.normal.wrapT = THREE.RepeatWrapping;
  }

  setMaterial() {
    this.material = new THREE.MeshStandardMaterial({
      map: this.textures.color,
      normalMap: this.textures.normal
    })
  }

  setMesh() {
    this.mesh = new THREE.Mesh(this.geometry, this.material);
    this.mesh.rotation.x = -Math.PI * 0.5;
    // this.mesh.receiveShadow = true;

    this.scene.add(this.mesh);
  }

  setFloorModel() {
    this.resource = this.resources.items.world;
    this.model = this.resource.scene;
    this.model.scale.set(100, 100, 100);

    // this.model.traverse((child) => {
    //   if(child instanceof THREE.Mesh) {
    //     child.castShadow = true;
    //     child.receiveShadow = true;
    //     child.geometry.computeVertexNormals();
    //   }
    // })

    this.scene.add(this.model);
  }
}