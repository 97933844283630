import * as THREE from 'three';
import { InteractionManager } from 'three.interactive';

import Experience from '../Experience';
import Dribbble from './Socials/Dribbble';
import Linkedin from './Socials/Linkedin';
import Behance from './Socials/Behance';
import Wera from './Wera';

import IntroAnimPath from '../Utils/IntroAnimPath';

import PointLight from './PointLight';

export default class Environment {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.renderer = this.experience.renderer;
    this.resources = this.experience.resources;
    this.camera = this.experience.camera;

    this.debug = this.experience.debug;
    this.interactionManager = null;
    this.currentIntersect = null;
    this.playerHelper = null;
    this.dribbble = new Dribbble();
    this.linkedin = new Linkedin();
    this.behance = new Behance();
    this.wera = new Wera();
    this.cameraPath = new IntroAnimPath();

    this.sizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    //TODO move
    this.mouse = new THREE.Vector2();
    this.pointLights = [];

    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder('environment');
      this.debugFolder.close();

      const axesHelper = new THREE.AxesHelper(10);
      this.scene.add(axesHelper);
    }

    this.setInteractionManager();

    this.setAmbientLight();
    this.setPointLights();
    this.setEnvironmentMap();
    this.createPlayer();


    // set interactives
    this.addInteractiveObject(this.dribbble);
    this.addInteractiveObject(this.linkedin);
    this.addInteractiveObject(this.behance);
    this.controlAimActivity();
    this.addInteractiveWera();
  }

  setInteractionManager() {
    this.interactionManager = new InteractionManager(
      this.renderer.instance,
      this.camera.instance,
      this.renderer.instance.domElement
    );
  }

  addInteractiveObject(ref) {
    const obj1 = ref.model;
    this.interactionManager.add(obj1);

    obj1.addEventListener('mouseover', (event) => {
      this.experience.aim.setActive();
    });

    obj1.addEventListener('mouseout', (event) => {
      this.experience.aim.setInactive();
    });

    obj1.addEventListener('click', (event) => {
      if (this.camera.isLocked) ref.click();
    });
  }

  addInteractiveWera() {
    this.interactionManager.add(this.wera.model);

    this.wera.model.addEventListener('mouseover', (event) => {
      this.experience.aim.setActiveAnimation();
    });

    this.wera.model.addEventListener('mouseout', (event) => {
      this.experience.aim.setInactive();
    });

    this.wera.model.addEventListener('click', (event) => {
      if (this.camera.isLocked) this.wera.playDance();
    })
  }

  controlAimActivity() {
    document.addEventListener('EventIsUnlocked', () => handleLock(), false);

    const handleLock = () => {
      this.experience.aim.setInactive();
    };
  }

  setAmbientLight() {
    let intensity = 0.25;
    this.ambientLight = new THREE.AmbientLight(0x0000ff, 0.25);
    this.scene.add(this.ambientLight);

    document.addEventListener('vero:magic-mode', (e) => {
      if(e.detail) {
        this.ambientLight.intensity = 0.25;
        this.ambientLight.color = new THREE.Color( 0x0000ff );
        intensity = 0.25;
      } else {
        this.ambientLight.intensity = 1.0;
        this.ambientLight.color = new THREE.Color( 0xFDF5E6 );
        intensity = 0.8;
      }

      this.environmentMap.updateMaterials = () => {
        this.scene.traverse((child) => {
          if (
            child instanceof THREE.Mesh &&
            child.material instanceof THREE.MeshStandardMaterial
          ) {
            child.material.envMapIntensity = intensity;
          }
        });
      };
    });
  }

  setPointLights() {
    //Left three
    new PointLight({x: 113, y: 34, z: 24.6, color: 0x3418e7, intensity: 860, distance: 100});
    new PointLight({x: 93, y: 48, z: 6.2, color: 0x3418e7, intensity: 700, distance: 100});
    new PointLight({x: 113, y: 30, z: 17.2, color: 0x2affd1, intensity: 670, distance: 100});
    new PointLight({x: 114, y: 12, z: -8.5, color: 0x3418e7, intensity: 900, distance: 100});

    //Rigth three
    new PointLight({x: -78, y: 34, z: -42, color: 0x3418e7, intensity: 860, distance: 100});
    new PointLight({x: -80, y: 13, z: -12, color: 0x3418e7, intensity: 700, distance: 100});
    new PointLight({x: -90, y: 30, z: -17.2, color: 0x2affd1, intensity: 670, distance: 100});
    new PointLight({x: -82, y: 1, z: -42, color: 0x3418e7, intensity: 900, distance: 100});
  }

  setEnvironmentMap() {
    this.environmentMap = {};
    this.environmentMap.intensity = 0.25;
    this.environmentMap.texture = this.resources.items.environmentMapTexture;
    this.environmentMap.texture.encoding = THREE.sRGBEncoding;
    this.scene.environment = this.environmentMap.texture;

    this.environmentMap.updateMaterials = () => {
      this.scene.traverse((child) => {
        if (
          child instanceof THREE.Mesh &&
          child.material instanceof THREE.MeshStandardMaterial
        ) {
          child.material.envMap = this.environmentMap.texture;
          child.material.envMapIntensity = this.environmentMap.intensity;
          child.material.needsUpdate = true;
        }
      });
    };

    this.environmentMap.updateMaterials();

    //Debug
    if (this.debug.active) {
      this.debugFolder
        .add(this.environmentMap, 'intensity')
        .name('envMapIntensity')
        .min(0)
        .max(4)
        .step(0.001)
        .onChange(this.environmentMap.updateMaterials);
    }
  }

  //Create player helper and player physics
  createPlayer() {
    this.experience.physics.addCharacterController();
  }

  update() {
    if (this.wera) this.wera.update();
    if (this.cameraPath.model) this.cameraPath.update();

    if (this.camera.isLocked) {
      //reset mouse position to raytrace from middle of the screen
      this.interactionManager.mouse.x = 0
      this.interactionManager.mouse.y = 0
      this.interactionManager.update();
    }
  }
}
