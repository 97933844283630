import Experience from '../Experience';
import Environment from './Environment';
import Floor from './Floor';

import MovingSphereBig from './Background/MovingSphereBig';
import MovingSphereSmall from './Background/MovingSphereSmall';
import ThreeParticles from './Effects/ThreeParticles';
import DustParticles from './Effects/DustParticles';
import Flashlight from './Effects/Flashlight';

import LowFps from '../Utils/LowFps';

export default class World {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    // Wait for all loaded
    this.resources.on('loaded', () => {
      this.floor = new Floor();
      this.enviornment = new Environment();

      //effects
      this.threeParticles = new ThreeParticles();
      this.dustParticles = new DustParticles();
      this.flashlight = new Flashlight();

      //backgrounds
      this.movingSphereSmall = new MovingSphereSmall();
      this.movingSphereBig = new MovingSphereBig();

      //checks
      this.lowFps = new LowFps(() => {
        // REMOVE on low fps
        this.movingSphereSmall.dispose();
        this.movingSphereSmall = null;

        this.movingSphereBig.dispose();
        this.movingSphereBig = null;

        this.threeParticles.dispose();
        this.threeParticles = null;

        this.dustParticles.dispose();
        this.dustParticles = null;
      });
    });
  }

  update() {
    //Geometries
    if(this.enviornment) this.enviornment.update();
    if(this.movingSphereSmall) this.movingSphereSmall.update();
    if(this.movingSphereBig) this.movingSphereBig.update();

    //Effects
    if(this.threeParticles) this.threeParticles.update();
    if(this.dustParticles) this.dustParticles.update();

    //Checks
    if(this.lowFps) this.lowFps.update();
  }
}
