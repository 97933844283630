import React from 'react';
import { createRoot } from 'react-dom/client';
import './style.css';
import Experience from './Experience/Experience';
import App from './Ui/App';
import GlobalState from './GlobalState';

let globalState = null;
let experience = null;

function Root() {
  return <App />;
}

const root = createRoot(document.getElementById('root'));
root.render(<Root />);


const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const setup = () => {
  globalState = new GlobalState();
  experience = new Experience(document.querySelector('canvas.webgl'));
}

if (!isMobile) {
  setup();
}